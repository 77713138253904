@import "reset.styl"
@import "helpers/**/*.styl"
@import "components/**/*.styl"

::selection,
::-webkit-selection
  background-color $primary
  color #fff

html, body
  font-family 'Open Sans', sans-serif
  width 100%
  max-width 100%
  min-height 100vh
  height auto
  overflow-x hidden
  overflow-y auto
  color #000

.img-fluid
  width 100%
  height auto
