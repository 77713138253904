.main-footer
  size(100%, 83px)
  wrapper()
  flexCenter()
  background-color $dark
  color #fff
  font-size 13px

  &-button
    size(40px, 40px)
    flexCenter()
    border none
    background-color $primary
    color #fff
    position absolute
    top calc(50% - 20px)
    right 15px
    border-radius 50%
    cursor pointer
    &:focus
      outline none
