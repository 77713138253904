.sobrados
  margin-top 25px
  pinterestRow(3, -10px)

  .sobrado
    pinterestCol(7px, 10px)
    img
      width 100%
      max-width 100%
      margin 0
