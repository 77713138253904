.container
  max-width 1170px
  padding-left 15px
  padding-right 15px
  margin 0 auto
  display block
  position relative
  clear both

// Flex Grid
row(m= -15px)
  display flex
  flex-flow row wrap
  align-items flex-start
  justify-content flex-start
  margin-left m
  margin-right m

column(w= 100%, p= 15px)
  position relative
  padding-left p
  padding-right p
  size(w, auto)

// Pinterest Grid
pinterestRow(count= 3, m= -15px)
  column-count count
  column-gap 0
  margin-left m
  margin-right m

pinterestCol(pY= 15px, pX= 15px)
  width 100%
  display inline-block
  padding pY pX
