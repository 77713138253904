// Project Grid
.projects
  margin-top 25px
  row()

// Project Column
.project
  column(25%)
  margin-bottom 30px
  @media(max-width $md)
    column(33.333%)
  @media(max-width $sm)
    column(50%)
  @media(max-width $xs)
    column()

  a
    &:hover
      text-decoration none !important

  // Card
  &-card
    wrapper()
    background-color #f9f9f9
    border 1px solid rgba(38,38,38,0.1)
    box-shadow 0 1px 2px rgba(34, 25, 25, 0.4)

    // Card Header
    &-header
      wrapper()
      padding 15px
      border-bottom 1px solid rgba(38,38,38,0.1)

    // Card Cover
    &-cover
      size(100%, 150px)
      cover()

    // Card Body
    &-body
      wrapper()
      padding 15px

    // Card Title & Card Text
    &-title,
    &-text
      textOverflow()

    &-title
      margin-bottom 5px

    &-text
      color rgba($dark, .6)
      font-size 12px

    // Card Footer
    &-footer
      text-align center
      font-size 13px
      padding 5px
      a
        background-color $primary
        color #FFF
        padding 10px
        display block
        width 100%
        i
          margin-right 5px
