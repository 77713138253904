.main-header

  &-body
    wrapper()

    &-container
      padding 10px 0
      display flex
      align-items center
      justify-content space-between
      @media(max-width $sm)
        justify-content center

  &-logo
    max-width 290px
    img
      size(100%, auto)

  &-phone
    color $primary
    font-weight 700
    font-size 24px
    a
      color inherit
      text-decoration none

    @media(max-width $sm)
      display none
