.project

  &-thumbnail
    max-width 100%

  &-gallery
    wrapper()
    margin 25px 0
    overflow-x auto
    white-space nowrap
    display block

    &-item
      size(100px, 100px)
      display inline-block
      border 1px solid rgba($dark, .3)
      padding 15px

      @media(max-width $sm)
        size(70px, 70px)

      a
        cursor zoom-in

    &-img
      size(100%, 100%)
      cover()

// Link De Orçamento
.orcamento-link
  text-align center
  background-color $primary
  color #FFF !important
  padding 10px
  display block
  width 100%
  margin 25px 0
  i
    margin-right 10px

  &:hover
    text-decoration none !important
