.breadcrumb
  background-color #f9f9f9
  border-bottom 1px solid rgba($dark, .1)
  padding 10px 0
  ol
    list-style none
    li
      display inline-block
      color rgba($dark, 1)
      font-size 13px
      & + li
        margin-left 10px
        border-left 1px solid rgba($dark, .3)
        padding-left 10px
      i
        margin-right 3px
      a
        color inherit
        text-decoration none
        color rgba($dark, .5)
