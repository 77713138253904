.main-header-bar
  wrapper()
  background-color $primary

  &-content
    size(100%, 31px)
    display flex
    justify-content space-between
    align-items center
    color #FFF
    font-size 13px
    @media(max-width $sm)
      justify-content center
      text-align center
      height auto
      padding 5px 0
    a
      color inherit
      text-decoration none
      @media(max-width $sm)
        display none

    address
      font-style normal
