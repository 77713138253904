.main-nav
  wrapper()
  background-color $primary
  z-index 10
  @media(max-width $custom_menu)
    background-color $dark
    flexCenter()
    size(100%, 49px)

  // List
  &-list
    ul
      text-align left
      margin-left -2px
      margin-right -2px
      wrapper()
    @media(max-width $custom_menu)
      size(250px, auto)
      display block
      position absolute
      top 49px
      right 0
      background-color $dark
      transform translateX(250px)
      transition all .3s
      &.active
        transform translateX(0)

  // Item
  &-item
    display inline-block
    list-style none
    padding 6px 2px
    @media(max-width $custom_menu)
      display block
      width 100%

  // Link
  &-link
    display block
    color #FFF
    padding 6px 10px
    color #fff
    font-size 12px
    text-transform uppercase
    font-weight 700
    line-height 25px
    text-decoration none
    transition all .3s
    &:hover,
    &.active
      background-color #fff
      color $primary

  // Toggle Menu
  &-toggle
    display none
    @media(max-width $custom_menu)
      flexCenter()
      size(100px, 100%)
      cursor pointer

      &-label
        color #fff
        font-size 12px
        text-transform uppercase
        font-weight 700

      &-button
        size(35px, 35px)
        display block
        border none
        background-color transparent
        margin-left 10px
        cursor pointer
        -webkit-tap-highlight-color transparent

        &:focus
          outline none

        .burger
          size(100%, 2px)
          background-color #fff
          border-radius 5px
          display block
          margin 7px 0
          transform-origin center
          position relative
          transition all .3s

        &.active

          .burger:nth-child(1)
            transform rotate(45deg) translate(0, 12px)
            left 8px
          .burger:nth-child(2)
            opacity 0
          .burger:nth-child(3)
            transform rotate(-45deg) translate(0, -12px)
            left 8px
