.contact

  // Contact Row
  &-row
    row()

  // Contact Infos
  &-infos
    column(50%)
    @media(max-width $sm)
      column(100%)

  // Contact Infos
  &-form
    column(50%)
    @media(max-width $sm)
      column(100%)
      margin-top 25px

    &-container
      wrapper()

      .input-group
        row(-5px)

        .input
          column(33.333%, 5px)
          @media(max-width $md)
            column(100%, 5px)

      .input-button
        wrapper()

  // Contact Title
  &-title
    margin-bottom 25px

  // Contact Box
  &-box
    padding 15px 0
    border-bottom 1px solid #e8e8e9
    &-title
      margin-bottom 15px

    a + a
      margin-left 10px

  // Map Iframe
  &-map
    wrapper()
    margin-top 70px
    iframe
      size(100%, 400px)
