size(w, h)
  width w
  height h

grid(color= cyan)
  border 1px solid color

wrapper()
  width 100%
  position relative

flexCenter()
  display flex
  justify-content center
  align-items center

cover()
  background-position center center
  background-size cover
  background-repeat no-repeat

textOverflow(w= 100%)
  white-space nowrap
  text-overflow ellipsis
  width w
  display block
  overflow hidden
