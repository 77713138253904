#banner

  &:hover
    .swiper-pagination-bullet,
    .swiper-button-prev,
    .swiper-button-next
      opacity 1

  img
    width 100%
    height auto

  .swiper-button-prev,
  .swiper-button-next
    opacity 0
    transition all .3s

  .swiper-button-disabled
    opacity .35 !important

  .swiper-pagination
    &-bullet
      size(10px, 10px)
      background-color #dedede
      opacity 0
      transition all .3s

      &-active
        background-color #22aeac
