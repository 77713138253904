.section
  wrapper()
  &-page
    padding 30px 0

    &.contato
      padding-bottom 0
      
    address
      font-style normal

    h1, h2, h3
    h4, h5, h6
      color $primary
      font-weight 700

      i
        margin-right 10px

    h1
      font-size 36px
    h2
      font-size 30px
    h3
      font-size 24px
    h4
      font-size 18px
    h5
      font-size 14px
    h6
      font-size 12px

    p
      line-height 1.42857143
      font-size 14px

      & + p
        margin-top 20px

    ol, ul
      margin-top 15px
      margin-left 15px
      li
        margin 5px 0

    img
      max-width 100%
      height auto
      display inline-block
      margin 20px 0
      &.half
        max-width 50%
        float left

    a
      color $primary
      text-decoration none
      &:hover
        text-decoration underline
