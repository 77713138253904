.input
  wrapper()
  margin-top 10px

  &-label
    display block
    max-width 100%
    margin-bottom 5px
    font-weight bold
    font-size 14px
    cursor pointer

  &-control
    font-family 'Open Sans', sans-serif
    display block
    width 100%
    padding 6px 12px
    font-size 14px
    line-height 1.42857143
    color #555
    background-color #fff
    background-image none
    border 1px solid #ccc
    border-radius 0
    box-shadow inset 0 1px 1px rgba(0, 0, 0, 0.075)

    &.textarea
      min-height 134px

    &:focus
      border-color #66afe9
      outline 0
      box-shadow inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6)

  &-button
    display inline-block
    margin-bottom 0
    font-weight normal
    text-align center
    vertical-align middle
    cursor pointer
    background-image none
    border 1px solid transparent
    white-space nowrap
    padding 10px 16px
    font-size 18px
    line-height 1.3333333
    border-radius 0
